/* Estilos base para el contenedor del formulario */
.form-wrapper {
  position: relative;
  transition: all 0.3s ease;
}

/* Estilos cuando está en modo deshabilitado */
.form-wrapper.disabled-mode {
  position: relative;
  pointer-events: none;
  filter: grayscale(20%);
}

/* Overlay que cubre el formulario */
.form-wrapper .form-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--overlay-color, rgba(245, 245, 250, 0.4));
  backdrop-filter: blur(0.5px);
  z-index: 10;
  border-radius: inherit;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
}

/* Mensaje de solo lectura */
.form-wrapper .read-only-message {
  position: absolute;
  top: 12px;
  right: 12px;
  background-color: rgba(50, 50, 70, 0.85);
  color: white;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  z-index: 30;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  opacity: 1;
  pointer-events: none;
}

/* Estilo para el icono de candado */
.form-wrapper .lock-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  z-index: 20;
  color: rgba(0, 0, 0, 0.7);
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  opacity: 1;
  padding: 8px;
}

.form-wrapper .lock-icon svg {
  width: 100%;
  height: 100%;
}

/* Estilos para todos los campos del formulario */
.form-wrapper.disabled-mode input,
.form-wrapper.disabled-mode select,
.form-wrapper.disabled-mode textarea,
.form-wrapper.disabled-mode button {
  color: #555;
  background-color: #f9f9f9;
  border-color: #e0e0e0;
  cursor: default;
}

/* Estilos para los botones específicamente */
.form-wrapper.disabled-mode button {
  opacity: 0.7;
  box-shadow: none;
}

/* Efecto sutil para campos enfocados normalmente */
.form-wrapper:not(.disabled-mode) input:focus,
.form-wrapper:not(.disabled-mode) select:focus,
.form-wrapper:not(.disabled-mode) textarea:focus {
  border-color: #4a90e2;
  box-shadow: 0 0 0 3px rgba(74, 144, 226, 0.2);
}

/* Transición suave al cambiar entre modos */
.form-wrapper,
.form-wrapper input,
.form-wrapper select,
.form-wrapper textarea,
.form-wrapper button {
  transition: all 0.2s ease;
}
